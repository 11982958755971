*,
::after,
::before {
  box-sizing: border-box;
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,100;1,200;1,600;1,700&family=Sacramento&display=swap");

:root {
  /* fonts */
  --headingFont: "Poppins", sans-serif;
  --bodyFont: "Poppins", sans-serif;

  /* colors */
  --green: #98c245;
  --black: #000000;
  --lightBlack: #121212;
  --white: #b6b4b4;
}

#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: var(--bodyFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.2rem;
}

h3 {
  font-size: 1.1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Change the navbar background when scrolled more than 100px */

.background-nav-no-scroll {
  background: var(--black) !important;
  transition: background 0.5s ease-in-out;
}

.background-nav-no-scroll .btn:hover,
.background-nav-no-scroll .btn:focus {
  color: white !important;
  background: var(--green) !important;
  border: 1px solid var(--green) !important;
}

/* change the scheme color of the navbar */
.white {
  background: white !important;
}
/* 
.white ul li a {
  color: var(--black) !important;
}

.white .btn {
  color: var(--black) !important;
  border: 1px solid var(--black) !important;
} */

.background-nav-scroll {
  -webkit-box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.75);
  background: white !important;
  transition: all 0.5s ease-in-out;
}

.background-nav-scroll .menu-humburguer {
  color: var(--black) !important;
}

.background-nav-scroll .logo {
  fill: var(--black) !important;
}

.background-nav-scroll li a {
  color: var(--white) !important;
}

.background-nav-scroll .btn {
  color: var(--white) !important;
  border: 1px solid var(--white) !important;
}

.background-nav-scroll .btn:hover,
.background-nav-scroll .btn:focus {
  color: white !important;
  background: var(--green) !important;
  border: 1px solid var(--green) !important;
}

@media (min-width: 768px) {
  .background-nav-scroll li a {
    color: var(--black) !important;
  }

  .background-nav-scroll .btn {
    color: var(--black) !important;
    border: 1px solid var(--black) !important;
  }

  .background-nav-scroll .btn,
  .background-nav-scroll .btn {
    color: var(--black) !important;
    background: white !important;
    border: 1px solid var(--black) !important;
  }

  .background-nav-scroll .btn:hover,
  .background-nav-scroll .btn:focus {
    color: var(--white) !important;
    background: var(--black) !important;
    border: 1px solid var(--black) !important;
  }

  /* change the scheme color of the navbar */

  .white ul li a {
    color: var(--black) !important;
  }

  .white .btn {
    color: var(--black) !important;
    border: 1px solid var(--black) !important;
  }
}
